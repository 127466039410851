import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-sans\",\"subsets\":[\"latin\"]}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"variable\":\"--font-heading\",\"subsets\":[\"latin\"]}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/packages/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/Users/bart/getscraping/getScraping/packages/web/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/packages/web/styles/tailwind.css");
